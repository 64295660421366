import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import 'url-search-params-polyfill';
import moment from 'moment';
import 'moment/locale/da';

// Bootstrap styled components
import NavigationTop from '../components/navigation/NavigationTop';
import NavigationBottom from '../components/navigation/NavigationBottom';
import Category from '../components/content/Category';
import Issues from '../components/content/Issues';
import DatePicker from '../components/content/DatePicker';
import SlotPicker from '../components/content/SlotPicker';
import DataFormsNew from '../components/content/DataFormsNew';
import AppointmentInfo from '../components/content/AppointmentInfo';
import AppointmentInfoTouch from '../components/content/AppointmentInfoTouch';
import DuplicateBooking from '../components/content/DuplicateBooking';
import ErrorBooking from '../components/content/ErrorBooking';
import Persons from '../components/content/Persons';
import OnlyOnePersons from '../components/content/OnlyOnePersons';
import Checkout from '../components/content/Checkout';
import Header from '../components/content/Header';

// Components
import StatusInfo from '../components/content/StatusInfo';
import ServiceGroups from '../components/content/ServiceGroups';
import IssueGroups from '../components/content/IssueGroups';
import Helmet from 'react-helmet';
import CheckoutTitle from '../components/content/CheckoutTitle';
import EnterInviteCode from "../components/content/EnterInviteCode";
import AppointmentTime from '../components/content/AppointmentTime';

// Translation
import { injectIntl } from "react-intl";
import { renderToStaticMarkup } from 'react-dom/server';
import Welcome from "../components/content/Welcome";
import {BarLoader} from "react-spinners";
import Modal from "react-modal";
import validateLocale from "../helpers/validateLocale";
// import { storeBookingSessionKey } from "../actions";

const query = new URLSearchParams(window.location.search);
const queryEmbeddedMode = query.get('embedded_mode')
const embedded_mode = queryEmbeddedMode === "true"
const querySummaryMode = query.get('summary_mode')
const summary_mode = querySummaryMode === "true"
const touchscreen_mode = query.get('touchscreen_mode') === 'true'
const touchscreen_id = query.get('touchscreen_id');
const desk_id = query.get('desk_id');
const show_logo = query.get('show_logo')
let language
let subtask_id

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_content: false,
      show_error: false,
      playing: false,
      poster: true,
      showAppDownloads: false,
      showDatePicker: false,
      last_person_count: null,
      multi_tasks: 0,
      noIssues: false,
      keyboard_state: 'closed',
      service_not_found: 0,
      selected_language: '',
      browserKoke: false,
      paperStatus: false,
      myMessage: ' '
    };

    language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : this.props.default_locale

    // check valid locale
    if (language && language.length > 0) {
      if (!validateLocale(language, this.props.locales)) {
        language = this.props.default_locale
      }
    }

    this.state.selected_language = language

  }
  componentDidMount() {
    this.props.storeBookingSessionKey(this.props.booking_session_key)
    window.addEventListener("orientationchange", function(event) {
      this.forceUpdate()
    });
    const invite_url_token = query.get('url_token') ? query.get('url_token') : '';
    // Checks if site_id or site_url_name is used. And then fetches the data.
    const pathArray = window.location.pathname.split('/');
    if (pathArray[2] === 'sites') {
      this.props
      .fetchSiteInfo(pathArray[3], language)
      .then(res => {
        if (!res.payload.data.show_services) {
          this.props.selectedService(res.payload.data.services[0].id);
          this.props.selectedServiceName(res.payload.data.services[0].name);
          this.props.selectedServiceData(res.payload.data.services[0]);
          if (res.payload.data.appointments_use_subtasks) {
            this.props.setNoIssues(false);
          } else {
            this.props.setNoIssues(true);
          }
          this.props.selectedDay('hidden');
          this.props.selectedSlot('hidden');
          this.props.resetSubtasks();
          this.props.resetPersons();
          if (!res.payload.data.services[0].visible_only_for_desks || this.props.session_info.isUser) {
            this.props.setSubtaskEdit();
          }
          this.setMultiTasks(
            res.payload.data.services[0].appointment_only_one_subtasks === true
              ? 0
              : 1
          );
          if (
            res.payload.data.services[0].subtask_groups &&
            res.payload.data.services[0].subtask_groups.length
          ) {
            this.props.setSubtaskGroupEdit();
          }
        } else {
          if (res.payload.data.service_groups && res.payload.data.service_groups.length) {
            this.props.setServiceGroupEdit();
          } else {
            if (res.payload.data.services.length)  {
              if (res.payload.data.services.filter(s => s.visible_only_for_desks === false).length || this.props.session_info.isUser) {
                this.props.setServiceEdit();
              }

            }
          }

          // select items with url params
          const service_id = query.get('service_id') ? parseInt(query.get('service_id')) : null
          const service_group_id = query.get('service_group_id') ? parseInt(query.get('service_group_id')) : null
          subtask_id = query.get('subtask_id') ? parseInt(query.get('subtask_id')) : null
          const slot_id = query.get('slot_id') ? parseInt(query.get('slot_id')) : null
          const time_of_slot = query.get('time_of_slot')
          const day = query.get('day')
          const subtask_items = query.get('subtask_items[]') ? JSON.parse(JSON.parse(query.get('subtask_items[]'))[0]) : null
          const embedded_mode = query.get('embedded_mode') === 'true'
          const touchscreen_mode = query.get('touchscreen_mode') === 'true'
          const touchscreen_or_embedded_mode_active = embedded_mode || touchscreen_mode;

          if (service_group_id){
            let service_group = res.payload.data.service_groups.filter(service_group => service_group.id === service_group_id)[0]

            // Pre select service group
            if (service_group){
              this.props.selectedServiceGroup(service_group_id);
              this.props.selectedServiceGroupData(service_group);
              this.props.setHideServiceGroupSelector();
              this.props.setServiceEdit();
            }
          }
          if (service_id) {
            let service = res.payload.data.services.filter(service => service.id === service_id)[0]

            // Pre select service
            if (service) {
              this.props.selectedService(service_id);
              this.props.selectedServiceName(service.name);
              this.props.selectedServiceData(service);
              this.props.setHideServiceSelector();
              this.props.setHideServiceGroupSelector();
              if (service.subtask_groups && service.subtask_groups.length) {
                this.props.setSubtaskGroupEdit();
              } else {
                this.props.setSubtaskEdit();
              }
              if (service.subtasks && service.subtasks.length && res.payload.data.appointments_use_subtasks) {
                this.props.setNoIssues(false);
              } else {
                this.props.setNoIssues(true);
              }
              this.setMultiTasks(
                service.appointment_only_one_subtasks === true
                  ? 0
                  : 1
              );
            } else {
              this.setState({ service_not_found: 1 })
            }
          }

          if (subtask_id && service_id) {
            let subtask = res.payload.data.subtasks.filter(subtask => subtask.id === subtask_id)[0]

            // Pre select subtask
            if (subtask) {
              if (subtask_items && subtask_items.length) {
                subtask_items.forEach(subtask_item => {
                  this.props.selectedSubtask(subtask);
                  this.props.updatePersons(
                    subtask.id,
                    subtask_item.number,
                    subtask.name,
                    false,
                    subtask.initial_minutes,
                    subtask.next_minutes
                  );
                })
                this.props.setHideSubtaskGroupSelector();
                this.props.setHideSubtaskSelector();
              } else {
                this.props.selectedSubtask(subtask);
                this.props.setHideSubtaskGroupSelector();
                this.props.setHideSubtaskSelector();
              }
            }

          }

          if (day) {
            const from_day = moment().format('YYYY-MM-DD');
            const to_day = moment()
              .add(12, 'weeks')
              .endOf('week')
              .format('YYYY-MM-DD');
            // ActionCreator "ajax request
            this.props.fetchAppointmentDays(
              res.payload.data.id,
              service_id,
              from_day,
              to_day,
              touchscreen_or_embedded_mode_active,
              this.props.no_issues ? 'no_issues' :
                [{
                  subtask_id: subtask_id,
                  number: 1
              }]
            );

            this.props.selectedDay(day)
            this.setState({ showDatePicker: true })
          }

          if (slot_id) {
            this.props.fetchAppointmentSlots(
              this.props.site_info.id,
              this.props.selected_service,
              day,
              this.props.no_issues === false
                ? [{
                  subtask_id: subtask_id,
                  number: 1
                }]
                : 'no_issues'
            );

            this.props.selectedSlot(slot_id)
            this.props.selectedSlotTimeOfSlot(time_of_slot)
          }
        }
        if (invite_url_token && invite_url_token.length) {
          this.props.validateInviteUrlToken(res.payload.data.id, invite_url_token, language)
        }
        this.setState({ show_content: true });
      })
      .catch(err => {
        console.log(err);
        this.setState({ show_error: true });
      });
    } else {
      this.props
      .fetchSiteUrlName(pathArray[3], language)
      .then(res => {
        let id = res.payload.data.id;
        if (!res.payload.data.show_services) {
          this.props.selectedService(res.payload.data.services[0].id);
          this.props.selectedServiceName(res.payload.data.services[0].name);
          this.props.selectedServiceData(res.payload.data.services[0]);
          if (res.payload.data.appointments_use_subtasks) {
            this.props.setNoIssues(false);
          } else {
            this.props.setNoIssues(true);
          }
          this.props.selectedDay('hidden');
          this.props.selectedSlot('hidden');
          this.props.resetSubtasks();
          this.props.resetPersons();
          this.props.setSubtaskEdit();
          this.setMultiTasks(
            res.payload.data.services[0].appointment_only_one_subtasks === true
              ? 0
              : 1
          );
          if (
            res.payload.data.services[0].subtask_groups &&
            res.payload.data.services[0].subtask_groups.length
          ) {
            this.props.setSubtaskGroupEdit();
          }
        } else {
          if (res.payload.data.service_groups && res.payload.data.service_groups.length) {
            this.props.setServiceGroupEdit();
          } else {
            if (res.payload.data.services.length)  {
              if (res.payload.data.services.filter(s => s.visible_only_for_desks === false).length || this.props.session_info.isUser) {
                this.props.setServiceEdit();
              }
            }
          }

          // select items with url params
          const service_id = query.get('service_id') ? parseInt(query.get('service_id')) : null
          const service_group_id = query.get('service_group_id') ? parseInt(query.get('service_group_id')) : null
          subtask_id = query.get('subtask_id') ? parseInt(query.get('subtask_id')) : null
          const slot_id = query.get('slot_id') ? parseInt(query.get('slot_id')) : null
          const time_of_slot = query.get('time_of_slot')
          const day = query.get('day')
          const subtask_items = query.get('subtask_items[]') ? JSON.parse(JSON.parse(query.get('subtask_items[]'))[0]) : null
          const embedded_mode = query.get('embedded_mode') === 'true'
          const touchscreen_mode = query.get('touchscreen_mode') === 'true'
          const touchscreen_or_embedded_mode_active = embedded_mode || touchscreen_mode;

          if (service_group_id){
            let service_group = res.payload.data.service_groups.filter(service_group => service_group.id === service_group_id)[0]

            // Pre select service group
            if (service_group){
              this.props.selectedServiceGroup(service_group_id);
              this.props.selectedServiceGroupData(service_group);
              this.props.setHideServiceGroupSelector();
              this.props.setServiceEdit();
            }
          }

          if (service_id) {
            let service = res.payload.data.services.filter(service => service.id === service_id)[0]

            // Pre select service
            if (service) {
              this.props.selectedService(service_id);
              this.props.selectedServiceName(service.name);
              this.props.selectedServiceData(service);
              this.props.setHideServiceSelector();
              this.props.setHideServiceGroupSelector();
              if (service.subtask_groups && service.subtask_groups.length) {
                this.props.setSubtaskGroupEdit();
              } else {
                this.props.setSubtaskEdit();
              }
              if (service.subtasks && service.subtasks.length && res.payload.data.appointments_use_subtasks) {
                this.props.setNoIssues(false);
              } else {
                this.props.setNoIssues(true);
              }
              this.setMultiTasks(
                service.appointment_only_one_subtasks === true
                  ? 0
                  : 1
              );
            } else {
              this.setState({ service_not_found: 1 })
            }
          }

          if (subtask_id && service_id) {
            let subtask = res.payload.data.subtasks.filter(subtask => subtask.id === subtask_id)[0]

            // Pre select subtask
            if (subtask) {
              if (subtask_items && subtask_items.length) {
                subtask_items.forEach(subtask_item => {
                  this.props.selectedSubtask(subtask);
                  this.props.updatePersons(
                    subtask.id,
                    subtask_item.number,
                    subtask.name,
                    false,
                    subtask.initial_minutes,
                    subtask.next_minutes
                  );
                })
                this.props.setHideSubtaskGroupSelector();
                this.props.setHideSubtaskSelector();
              } else {
                this.props.selectedSubtask(subtask);
                this.props.setHideSubtaskGroupSelector();
                this.props.setHideSubtaskSelector();
              }
            }

          }

          if (day) {
            const from_day = moment().format('YYYY-MM-DD');
            const to_day = moment()
              .add(12, 'weeks')
              .endOf('week')
              .format('YYYY-MM-DD');
            // ActionCreator "ajax request
            this.props.fetchAppointmentDays(
              res.payload.data.id,
              service_id,
              from_day,
              to_day,
              touchscreen_or_embedded_mode_active,
              this.props.no_issues ? 'no_issues' :
                [{
                  subtask_id: subtask_id,
                  number: 1
                }]
            );

            this.props.selectedDay(day)
            this.setState({ showDatePicker: true })
          }

          if (slot_id) {
            this.props.fetchAppointmentSlots(
              this.props.site_info.id,
              this.props.selected_service,
              day,
              this.props.no_issues === false
                ? [{
                  subtask_id: subtask_id,
                  number: 1
                }]
                : 'no_issues'
            );

            this.props.selectedSlot(slot_id)
            this.props.selectedSlotTimeOfSlot(time_of_slot)
          }
        }
        if (invite_url_token && invite_url_token.length) {
          this.props.validateInviteUrlToken(res.payload.data.id, invite_url_token, language)
        }
        this.setState({ show_content: true });
      })
        .catch(err => {
          console.log(err);
          this.setState({ show_error: true });
        });
    }
    //this.props.fetchSessionInfo();
    if (this.props.current_session && this.props.current_session.username) {
      this.props.setSessionInfo(this.props.current_session)
    }
  }

  handlePrinterEvents = (e) => {
    if (typeof e.data.component !== "undefined") {
      let test = e.data.component + ' : ' + e.data.command + ' : ' + this.state.paperStatus;
      if (typeof e.data.data !== "undefined" && typeof e.data.data.state !== "undefined") {
        test = test + ' : ' + e.data.data.state
      }
    }
    // values: "ok", "'near-end", "empty", null
    if (e.data.component === "printer" && e.data.command === "paper-state-result") {
      if (e.data.data.state === 'empty' || e.data.data.state === null) {
        if (this.state.paperStatus === false) {
          this.setState({paperStatus: true})
          setPaperStatus(desk_id, touchscreen_id, true)
        }
      } else {
        if (this.state.paperStatus === true) {
          this.setState({paperStatus: false})
          setPaperStatus(desk_id, touchscreen_id, false)
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user_count_persons !== this.props.user_count_persons) {
      if (this.props.selected_subtask.length) {
        this.checkSelectedTimeFrame();
      }
    }

    if (prevProps.ui.validated !== this.props.ui.validated) {
      if (this.props.ui.validated.service_data) {
        this.setState({ service_not_found: 0 })

        let service = this.props.ui.validated.service_data

        // Pre select service
        if (service) {
          this.props.selectedService(service.id);
          this.props.selectedServiceName(service.name);
          this.props.selectedServiceData(service);
          this.props.setHideServiceSelector();
          this.props.setHideServiceGroupSelector();
          if (service.subtask_groups && service.subtask_groups.length) {
            this.props.setSubtaskGroupEdit();
          } else {
            this.props.setSubtaskEdit();
          }
          if (service.subtasks && service.subtasks.length && this.props.site_info.appointments_use_subtasks) {
            this.props.setNoIssues(false);
          } else {
            this.props.setNoIssues(true);
          }
          this.setMultiTasks(
            service.appointment_only_one_subtasks === true
              ? 0
              : 1
          );
        } else {
          this.setState({ service_not_found: 1 })
        }
      }
    }
    document.documentElement.lang = this.state.selected_language;
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handlePrinterEvents);
  }
  checkSelectedTimeFrame() {
    let time_frame = 0;
    for (let i = 0; i < this.props.user_count_persons.length; i++) {
      if (this.props.user_count_persons[i].number > 1) {
        let minutes = 0;
        minutes =
          this.props.user_count_persons[i].next_minutes *
          (this.props.user_count_persons[i].number - 1);

        time_frame =
          time_frame +
          (minutes + this.props.user_count_persons[i].initial_minutes);
      } else {
        time_frame =
          time_frame + this.props.user_count_persons[i].initial_minutes;
      }
    }

    this.props.updateTimeFrame(time_frame);
  }
  playAndPauseToggle() {
    this.setState({ playing: !this.state.playing, poster: false });
  }
  renderAppDownloads() {
    this.setState({ showAppDownloads: true });
  }
  afterPersonsSelected(boolean) {
    this.setState({ showDatePicker: boolean });
  }
  hideDatePicker() {
    this.setState({ showDatePicker: false });
  }
  personIncrementDecrementChange() {
    this.setState({ showDatePicker: false });
  }
  handleReset() {
    this.setState({ showDatePicker: false, noIssues: false });
    this.props.selectedDay('hidden');
    this.props.selectedSlot('hidden');
  }
  setMultiTasks(status) {
    this.setState({ multi_tasks: status });
  }
  getKeyboardState(state) {
    this.setState({ keyboard_state: state });
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const modalCustomStyles = {
      content : {
        top                   : '45%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'

      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 110
      },
    };

    if (language !== undefined) {
      switch (language) {
        case 'en':
          moment.updateLocale('en', {
            week: {
              dow: 1,
              doy: 4
            }
          });
          break;
        case 'da':
          moment.updateLocale('da', {
            week: {
              dow: 1,
              doy: 4
            }
          });
          break;
        case 'de':
          moment.updateLocale('de', {
            week: {
              dow: 1,
              doy: 4
            }
          });
          break;
        default:
          moment.updateLocale('en', {
            week: {
              dow: 1,
              doy: 4
            }
          });
          break;
      }
    }
    const {intl} = this.props
    if (this.state.show_content) {
      if (!touchscreen_mode && !embedded_mode) {
        return (
          <Fragment>
            <div
              id="background-image"
              style={{
                backgroundColor: this.props.site_info.background_color,
                minHeight: '100vh',
                backgroundSize: 'cover',
                backgroundImage: `url(${
                  process.env.NODE_ENV === 'development'
                    ? this.props.site_info.background_image !== '/background_images/original/missing.png' ? 'http://localhost:3000' +
                      this.props.site_info.background_image : null
                    : this.props.site_info.background_image !== '/background_images/original/missing.png' ? this.props.site_info.background_image : null
                } )`,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                fontFamily: 'inherit'
              }}>
              {this.props.site_info.background_video_url ? (
                <video
                  style={{ zIndex: 0 }}
                  id="video-background"
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  poster={this.props.site_info.background_image}>
                  <source
                    src={this.props.site_info.background_video_url}
                    type="video/webm"
                  />
                </video>
              ) : null}
              <div>
                { this.props.site_info.show_header ? <NavigationTop locales={this.props.locales} possible_locales={this.props.possible_locales} language={this.props.language}/> : null}
                <div >
                  {(!this.props.site_info.disable_appointments_with_informations &&
                      !this.props.site_info.appointment_invite_only_mode) || ( this.props.ui.validated && this.props.ui.validated.url_token ) || ( this.props.session_info.isUser ) ?
                      (
                          this.state.service_not_found !== 1 ?
                              <div className="space_right_and_left">
                                <div className="zindexbooster">
                                  <Welcome
                                      touchscreen_mode={touchscreen_mode}
                                      show_header={this.props.show_header}
                                      locales={this.props.locales}
                                      possible_locales={this.props.possible_locales}
                                      language={this.props.language}
                                      embedded_mode={embedded_mode}
                                  />
                                </div>
                                { this.props.site_info.invite_code_window && this.props.ui.validated && !this.props.ui.validated.id && !this.props.session_info.isUser ? <EnterInviteCode /> : null }
                                {this.props.ui.confirm_greetings || !this.props.site_info.show_greetings ?
                                    <Fragment>
                                      {
                                        this.props.site_info.service_groups && this.props.site_info.service_groups.length && this.props.site_info.show_services ?
                                            !this.props.ui.service_group_selector_hide ?
                                                <ServiceGroups
                                                    handleReset={this.handleReset.bind(this)}
                                                    isUser={this.props.session_info.isUser}
                                                    touchscreen_mode={touchscreen_mode}
                                                /> : null
                                            : null
                                      }
                                      {
                                        (this.props.ui.service_edit || this.props.selected_service !== 'hidden') ?
                                            !this.props.ui.service_selector_hide ?
                                                <div>
                                                  <Category
                                                      handleReset={this.handleReset.bind(this)}
                                                      setMultiTasks={this.setMultiTasks.bind(this)}
                                                      afterPersonsSelected={this.afterPersonsSelected.bind(this)}
                                                      hideDatePicker={this.hideDatePicker.bind(this)}
                                                      touchscreen_mode={touchscreen_mode}
                                                  />
                                                </div>
                                                : null
                                            : null
                                      }
                                      {!this.props.selected_service_data.info_only && this.props.selected_service !== 'hidden' &&
                                      (this.props.selected_service_data.subtask_groups &&
                                          this.props.selected_service_data.subtask_groups.length) ? (
                                          !this.props.ui.subtask_group_selector_hide ?
                                              <IssueGroups
                                                  handleReset={this.handleReset.bind(this)}
                                                  isUser={this.props.session_info.isUser}
                                                  touchscreen_mode={touchscreen_mode}
                                              /> : null
                                      ) : null}
                                      {(this.props.selected_service !== 'hidden' &&
                                          this.props.selected_subtask_group) !== 'hidden' ||
                                      (!this.props.selected_service_data.info_only && this.props.selected_service !== 'hidden') ? (
                                          this.props.no_issues !== true ? (
                                              (this.props.ui.subtask_edit || this.props.selected_subtask.length) ?
                                                  !this.props.ui.subtask_selector_hide ? <Issues
                                                      hideDatePicker={this.hideDatePicker.bind(this)}
                                                      touchscreen_mode={touchscreen_mode}
                                                  /> : null : null
                                          ) : null
                                      ) : null}
                                      {this.props.ui.service_selector_hide ?
                                          this.props.site_info.show_your_choice ? <CheckoutTitle /> : null : null
                                      }
                                      {this.props.selected_subtask.length
                                          ? this.props.selected_subtask.map(subtask => {
                                            if (subtask.max_tasks > 1) {
                                              return (
                                                  <Persons
                                                      key={subtask.id}
                                                      subtask={subtask}
                                                      personIncrementDecrementChange={this.personIncrementDecrementChange.bind(
                                                          this
                                                      )}
                                                  />
                                              );
                                            } else {
                                              return (
                                                  <OnlyOnePersons key={subtask.id} subtask={subtask} />
                                              );
                                            }
                                          })
                                          : null}
                                      {this.state.multi_tasks === 1 || subtask_id ? (
                                          this.props.user_count_persons.length > 0 &&
                                          this.props.selected_subtask.length ===
                                          this.props.user_count_persons.length ? (
                                              <Checkout
                                                  afterPersonsSelected={this.afterPersonsSelected.bind(
                                                      this
                                                  )}
                                                  timeFrameExceeded={
                                                    this.props.ui.time_frame >
                                                    this.props.selected_service_data
                                                        .appointments_max_booking_time_frame
                                                        ? this.props.selected_service_data
                                                            .appointments_max_booking_time_frame === 0
                                                            ? 0
                                                            : 1
                                                        : 0
                                                  }
                                              />
                                          ) : null
                                      ) : this.props.user_count_persons.length > 0 ? (
                                          <div>
                                            <Checkout
                                                afterPersonsSelected={this.afterPersonsSelected.bind(this)}
                                                timeFrameExceeded={
                                                  this.props.ui.time_frame >
                                                  this.props.selected_service_data
                                                      .appointments_max_booking_time_frame
                                                      ? this.props.selected_service_data
                                                          .appointments_max_booking_time_frame === 0
                                                          ? 0
                                                          : 1
                                                      : 0
                                                }
                                            />
                                          </div>
                                      ) : null}
                                      {this.props.no_issues === true && !this.props.selected_service_data.info_only ? (
                                          <Checkout
                                              afterPersonsSelected={this.afterPersonsSelected.bind(this)}
                                              timeFrameExceeded={0}
                                          />
                                      ) : null}
                                    </Fragment> : null
                                }
                                {this.state.showDatePicker &&
                                (this.props.selected_subtask.length || this.props.no_issues) && this.props.ui.day_edit ? (
                                    <DatePicker default_locale={this.props.default_locale} recaptcha_site_key={this.props.recaptcha_site_key} />
                                ) : null}
                                {this.props.selected_day !== 'hidden' &&
                                (this.props.selected_subtask.length || this.props.no_issues)  && this.props.ui.slot_edit ? (
                                    <SlotPicker />
                                ) : null}
                                {this.props.appointment_times.length ?
                                    <AppointmentTime /> : null
                                }
                                {this.props.selected_slot !== 'hidden' &&
                                (this.props.selected_subtask.length || this.props.no_issues) ? (
                                    this.props.booking.id ? (
                                        <AppointmentInfo reset={this.handleReset.bind(this)} site={this.props.site} paperStatus={this.state.paperStatus} browserKoke={this.state.browserKoke} />
                                    ) : this.props.booking.error === 1008 ? (
                                        <DuplicateBooking />
                                    ) : this.props.booking.error === 1001 ? (
                                            <ErrorBooking />
                                        )
                                        : !(this.props.selected_service_data.info_fields.length > 0)  ?
                                            <div className="container"><p style={{background: 'white', padding: '20px'}}>No info fields set!</p></div>
                                            : null
                                ) : null}
                                {this.props.ui.data_edit && this.props.selected_slot !== 'hidden' && (this.props.selected_subtask.length || this.props.no_issues) ?
                                    <DataFormsNew user_infos={this.props.user_infos} default_locale={this.props.default_locale} recaptcha_site_key={this.props.recaptcha_site_key} /> : null}
                              </div> :  null
                      )
                      : (
                          <div>
                            <Helmet>
                              <style>{`
                                .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
                                  background-color: ${
                                            this.props.site_info.heading_background
                                        };
                                  color: ${this.props.site_info.heading_text};
                                  font-size: 1.1em;
                                  font-weight: 600;
                                  padding: 8px;
                                  border: 1px solid #CBCBCBFF;
                                  border-bottom: none;
                                  border-radius: 5px 5px 0px 0px;
                                }
                              `}
                              </style>
                            </Helmet>
                            {!embedded_mode ?
                                <Header
                                    customer_icon={this.props.site_info.list_image_url}
                                    site_info={this.props.site_info.name}
                                    text={intl.formatMessage({id: "issues.browser_header"})}
                                /> : null}
                            <div
                                className={`container animated fadeIn ${
                                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                                }`}
                                style={{ marginTop: '70px' }}>
                              <div className="row">
                                <div className="col-md-12 issues_header_left">
                                  {intl.formatMessage({id: "app.important"})}
                                </div>
                              </div>
                              <div
                                  className="row inline-shadow"
                                  style={{
                                    backgroundColor: this.props.site_info.content_background,
                                    color: this.props.site_info.content_text
                                  }}>
                                <div
                                    style={{ fontSize: '1.7rem', padding: '10px' }}
                                    dangerouslySetInnerHTML={this.createMarkup(
                                        this.props.site_info.disable_appointments_information
                                    )}
                                />
                              </div>
                            </div>
                            {this.props.site_info.invite_code_window && !this.props.session_info.isUser ? <EnterInviteCode /> : null}
                          </div>
                      )}
                </div>
                {this.props.app_request === false ? <NavigationBottom
                    legal_notice={this.props.site_info.customer_legal_notice_url}
                    policy={this.props.site_info.customer_policy_url}
                    terms_of_use={this.props.site_info.customer_terms_of_use_url}
                  /> : null}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return (
          <div>
            {embedded_mode ?
              <Helmet>
                <style>{`
                .container {
                  width: 98%;
                  width: calc(100% - 20px);
                  overflow-x: hidden;
                  background: ${this.props.site_info.background_color};
                }
                .container:first-of-type {
                  padding-top: 10px;
                  margin-bottom: 0;
                }
                
                .container:last-of-type {
                  margin-bottom: 0;
                }
              `}</style>
              </Helmet> : null
            }
            <div
              id="background-image"
              style={{
                backgroundColor: this.props.site_info.background_color,
                minHeight: '100vh',
                backgroundSize: 'cover',
                backgroundImage: !embedded_mode ? `url(${this.props.site_info.background_image} )` : null,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed'
              }}>
              {!embedded_mode ? this.props.site_info.background_video_url ? (
                <video
                  id="video-background"
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  poster="https://www.nyidanmark.dk/Assets/images/background/scene00001.png">
                  <source
                    src={this.props.site_info.background_video_url}
                    type="video/webm"
                  />
                </video>
              ) : null : null}
              {show_logo ? null : <NavigationTop locales={this.props.locales} possible_locales={this.props.possible_locales} language={this.props.language}/>}
              {this.props.site_info.show_greetings || show_logo ? <Welcome touchscreen_mode={touchscreen_mode}/> : null}
              {!this.props.site_info.disable_appointments_with_informations ? (
                this.props.ui.confirm_greetings || !this.props.site_info.show_greetings ?
                  <div className={embedded_mode ? 'content-scale' : null} style={embedded_mode ? { marginTop: '50px'} : {}} >
                    {
                      this.props.site_info.service_groups && this.props.site_info.service_groups.length && this.props.site_info.show_services ?
                        !this.props.ui.service_group_selector_hide ? <ServiceGroups
                            handleReset={this.handleReset.bind(this)}
                            isUser={this.props.session_info.isUser}
                            touchscreen_mode={touchscreen_mode}
                        /> : null
                        : null
                    }
                    {
                      (this.props.ui.service_edit || this.props.selected_service !== 'hidden') ?
                        !this.props.ui.service_selector_hide && !this.state.showDatePicker ?
                          <Category
                            handleReset={this.handleReset.bind(this)}
                            setMultiTasks={this.setMultiTasks.bind(this)}
                            afterPersonsSelected={this.afterPersonsSelected.bind(this)}
                            hideDatePicker={this.hideDatePicker.bind(this)}
                            touchscreen_mode={touchscreen_mode}
                          /> : null
                        : null
                    }
                    {!this.props.selected_service_data.info_only && this.props.selected_service !== 'hidden' &&
                    (this.props.selected_service_data.subtask_groups &&
                      this.props.selected_service_data.subtask_groups.length) ? (
                      this.props.ui.subtask_group_edit || this.props.ui.subtask_edit ?
                          <IssueGroups
                              handleReset={this.handleReset.bind(this)}
                              isUser={this.props.session_info.isUser}
                              touchscreen_mode={touchscreen_mode}
                          /> : null
                    ) : null}
                    {this.props.selected_service !== 'hidden' ? (
                      this.props.no_issues !== true ? (
                        this.props.ui.subtask_edit ? <Issues
                          hideDatePicker={this.hideDatePicker.bind(this)}
                          touchscreen_mode={touchscreen_mode}
                        /> : null
                      ) : null
                    ) : null}
                    {!this.state.showDatePicker
                      ? this.props.selected_subtask.length &&
                      this.props.ui.subtask_edit
                        ? this.props.selected_subtask.map(subtask => {
                          if (subtask.max_tasks > 1) {
                            return (
                              <Persons
                                key={subtask.id}
                                subtask={subtask}
                                personIncrementDecrementChange={this.personIncrementDecrementChange.bind(
                                  this
                                )}
                              />
                            );
                          } else {
                            return (
                              <OnlyOnePersons
                                key={subtask.id}
                                subtask={subtask}
                              />
                            );
                          }
                        })
                        : null
                      : null}
                    {!this.state.showDatePicker && this.props.ui.subtask_edit ? (
                      this.state.multi_tasks === 1 ? (
                        this.props.user_count_persons.length > 0 &&
                        this.props.selected_subtask.length ===
                        this.props.user_count_persons.length ? (
                          <Checkout
                            afterPersonsSelected={this.afterPersonsSelected.bind(
                              this
                            )}
                            timeFrameExceeded={
                              this.props.ui.time_frame >
                              this.props.selected_service_data
                                .appointments_max_booking_time_frame
                                ? this.props.selected_service_data
                                  .appointments_max_booking_time_frame === 0
                                ? 0
                                : 1
                                : 0
                            }
                          />
                        ) : null
                      ) : this.props.user_count_persons.length > 0 ? (
                        <Checkout
                          afterPersonsSelected={this.afterPersonsSelected.bind(
                            this
                          )}
                          timeFrameExceeded={
                            this.props.ui.time_frame >
                            this.props.selected_service_data
                              .appointments_max_booking_time_frame
                              ? this.props.selected_service_data
                                .appointments_max_booking_time_frame === 0
                              ? 0
                              : 1
                              : 0
                          }
                        />
                      ) : null
                    ) : null}
                    {!this.state.showDatePicker && this.props.ui.subtask_edit ? (
                      this.props.no_issues === true ? (
                        <Checkout
                          afterPersonsSelected={this.afterPersonsSelected.bind(
                            this
                          )}
                          timeFrameExceeded={0}
                        />
                      ) : null
                    ) : null}
                    {this.props.selected_slot === 'hidden' ? (
                      this.state.showDatePicker && this.props.ui.day_edit ? (
                        <DatePicker touchscreen_mode={touchscreen_mode} />
                      ) : null
                    ) : null}
                    {this.props.selected_slot === 'hidden' ||
                    this.props.ui.slot_edit ? (
                      this.props.selected_day !== 'hidden' &&
                      this.props.ui.slot_edit ? (
                        <SlotPicker />
                      ) : null
                    ) : null}
                    {(this.props.selected_slot !== 'hidden' &&
                      this.props.ui.data_edit) ||
                    this.props.selected_slot !== 'hidden' ? (
                      this.props.booking.id ? (
                        touchscreen_mode ? (
                          <AppointmentInfoTouch
                            reset={this.handleReset.bind(this)}
                            paperStatus={this.state.paperStatus}
                            browserKoke={this.state.browserKoke}
                            afterPersonsSelected={this.afterPersonsSelected.bind(
                              this
                            )}
                            on_site_confirmation={this.props.on_site_confirmation}
                            default_locale={this.props.default_locale}
                          />
                        ) : (
                          <AppointmentInfo reset={this.handleReset.bind(this)} />
                        )
                      ) : this.props.booking.error === 1008 ? (
                        <DuplicateBooking
                          afterPersonsSelected={this.afterPersonsSelected.bind(
                            this
                          )}
                        />
                      ) : !touchscreen_mode ? (
                        this.props.selected_service_data.info_fields.length > 0 ?
                            <DataFormsNew user_infos={this.props.user_infos} default_locale={this.props.default_locale} recaptcha_site_key={this.props.recaptcha_site_key} /> : <div className="container"><p style={{background: 'white', padding: '20px'}}>No info fields set!</p></div>
                      ) : this.props.ui.data_edit ? (
                          this.props.selected_service_data.info_fields.length > 0 ?
                              <DataFormsNew user_infos={this.props.user_infos} default_locale={this.props.default_locale} recaptcha_site_key={this.props.recaptcha_site_key} /> : <div className="container"><p style={{background: 'white', padding: '20px'}}>No info fields set!</p></div>
                      ) : null
                    ) : null}
                    <StatusInfo
                      keyboardState={this.state.keyboard_state}
                      afterPersonsSelected={this.afterPersonsSelected.bind(this)}
                      embedded_mode={embedded_mode}
                      summary_mode={summary_mode}
                      browserKoke={this.state.browserKoke}
                      touchscreen_mode={touchscreen_mode}
                    />
                  </div> : null
              ) : (
                <div>
                  <Helmet>
                    <style>{`
                        .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
                          background-color: ${
                            this.props.site_info.heading_background
                          };
                          color: ${this.props.site_info.heading_text};
                          font-size: 1.1em;
                          font-weight: 600;
                          padding: 8px;
                          border: 1px solid #CBCBCBFF;
                          border-bottom: none;
                          border-radius: 5px 5px 0px 0px;
                        }
                      `}</style>
                  </Helmet>
                  <Header
                    customer_icon={this.props.site_info.list_image_url}
                    site_info={this.props.site_info.name}
                    text={intl.formatMessage({id: "issues.browser_header"})}
                  />
                  <div
                    className={`container animated fadeIn ${
                      this.props.site_info.drop_shadows ? 'drop-shadow' : null
                    }`}
                    style={{ marginTop: '70px' }}>
                    <div className="row">
                      <div className="col-md-12 issues_header_left">
                        {intl.formatMessage({id: "app.important"})}
                      </div>
                    </div>
                    <div
                      className="row inline-shadow"
                      style={{
                        backgroundColor: this.props.site_info.content_background,
                        color: this.props.site_info.content_text
                      }}>
                      <div
                        style={{ fontSize: '1.7rem', padding: '10px' }}
                        dangerouslySetInnerHTML={this.createMarkup(
                          this.props.site_info.disable_appointments_information
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }
    } else {
      return (
        <Modal
          isOpen={!this.state.show_content}
          ariaHideApp={false}
          style={modalCustomStyles}
        >
          <BarLoader
            sizeUnit={'px'}
            height={30}
            width={'100%'}
            color={this.props.site_info.loading_indicator}
            loading={true}
          />
        </Modal>
      )
    }
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    slots: state.slots,
    booking: state.booking,
    selected_service: state.user_selected_service,
    selected_subtask_group: state.user_selected_subtask_group,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    user_count_persons: state.user_count_persons,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui,
    services: state.service_list.all,
    session_info: state.session_info,
    appointment_times: state.appointment_time,
    selected_language: state.user_selected_language
  };
}

function validLocale(locale, possible_locales) {
  return possible_locales.some(function(lang) {
    return lang.code === locale;
  });
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(App)
);
