import React, { Fragment } from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import { Field, useField } from 'formik'
import classNames from 'classnames'
import styles from '../../../styles/dataforms.module.css'
import PhoneInput from '../../phone_number_input/PhoneInput';
import CustomInput from "../../CustomInput";
import '../../phone_number_input/style/style.css'

const TextField = (props) => {
  return (
    <Fragment>
      <label className={styles['label-custom']}>
        {props.label} {props.confirm_text ? <span>{''}{props.confirm_text}</span> : null}
        {props.required ? (
          <span className={styles.required}> *</span>
        ) : (
          <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
        )}
        <div style={{ float: 'right' }}>
          <span className={styles.error}>{props.touched && props.error}</span>
        </div>
      </label>
      <div
        className={classNames(
          styles['input-group'],
          styles['input-group-icon'],
          props.shadow ? styles.drop_shadow : null
        )}
      >
        <input
          name={props.name}
          type="text"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          style={props.readOnly === props.name ? {background: '#cccccc'} : props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
          readOnly={props.readOnly}
          onClick={() => props.activeInputName(props.name)}
        />
        <div className={styles['input-icon']}>
          <i className={`fas ${props.icon}`}></i>
        </div>
      </div>
    </Fragment>
  )
}

const NumberField = (props) => {
  return (
    <Fragment>
      <label className={styles['label-custom']}>
        {props.label}
        {props.required ? (
          <span className={styles.required}> *</span>
        ) : (
          <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
        )}
        <div style={{ float: 'right' }}>
          <span className={styles.error}>{props.touched && props.error}</span>
        </div>
      </label>
      <div
        className={classNames(
          styles['input-group'],
          styles['input-group-icon'],
          props.shadow ? styles.drop_shadow : null
        )}
      >
        <input
          name={props.name}
          type="number"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          readOnly={props.readOnly}
          style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
          onClick={() => props.activeInputName(props.name)}
        />
        <div className={styles['input-icon']}>
          <i className={`fas ${props.icon}`}></i>
        </div>
      </div>
    </Fragment>
  )
}

const TextArea = (props) => {
  return (
    <Fragment>
      <label className={styles['label-custom']}>
        {props.label}
        {props.required ? (
          <span className={styles.required}> *</span>
        ) : (
          <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
        )}
        <div style={{ float: 'right' }}>
          <span>
            <span style={{paddingRight: '10px'}} className={styles.error}>{props.touched && props.error}</span>
            <span
              style={{
                color: props.value.length < 3500 ? 'var(--success-color)' : 'var(--error-color)',
              }}
            >
              {props.value.length}
            </span>{' '}
            / <span style={{ color: 'var(--main-color)' }}>3500</span>
          </span>
        </div>
      </label>
      <div className={classNames(styles['input-group'], styles['input-group-icon'])}>
        <textarea
          className={props.shadow ? styles.drop_shadow : null}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          readOnly={props.readOnly}
          style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
          onClick={() => props.activeInputName(props.name)}
        />
        <div className={styles['input-icon']}>
          <i className={`fas ${props.icon}`}></i>
        </div>
      </div>
    </Fragment>
  )
}

const CheckBox = (props) => {
  return (
    <Fragment>
      <div className={styles['input-group']}>
        {props.touched && props.error ? (
          <div style={{ top: '2.5em', }} className={styles['floating-checkbox-label']}></div>
        ) : null}
        <input
          type="checkbox"
          name={props.name}
          onChange={props.onChange}
          id={props.name}
          checked={props.value}
        />
        <label htmlFor={props.name}>
          {props.label} <span style={{color: 'var(--required-color)' }}>*</span>
          <span className={styles.error} style={{marginLeft: '10px', fontSize: '15px'}}>{props.touched && props.error}</span>
        </label>
      </div>
    </Fragment>
  )
}

const RadioGroup = (props) => {
  return (
      <Fragment>
        <label className={styles['label-custom']}>
          {props.label}
          {props.required ? (
              <span className={styles.required}> *</span>
          ) : (
              <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"}/></span>
          )}
          <div style={{float: 'right'}}>
            <span className={styles.error}>{props.touched && props.error}</span>
          </div>
        </label>
        <div
            className={classNames(styles['input-group'], props.shadow ? styles.drop_shadow : null)}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'stretch',
            }}
        >
          {props.options.map((item) => (
              <div
                  key={item.id}
                  style={{
                    flex: `1 0 ${100 / Math.min(3, props.options.length)}%`,
                    maxWidth: `${100 / Math.min(3, props.options.length)}%`,
                    boxSizing: 'border-box',
                    margin: '0',
                    padding: '0',
                    display: 'flex',
                    alignItems: 'stretch',
                  }}
              >
                  <input
                    type="radio"
                    name={props.name}
                    value={item.value}
                    id={item.id}
                    onChange={props.onChange}
                    checked={props.value === item.value}
                />
                <label
                    htmlFor={item.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: '1',
                      border: props.touched && props.value === "" ? { borderColor: 'var(--error-color)' } : null,
                      padding: '10px',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      cursor: 'pointer',
                      whiteSpace: 'normal',
                      overflow: 'visible',
                    }}
                >
                  {item.value}
                </label>
              </div>
          ))}
        </div>
      </Fragment>
  )
}

const GenderPicker = (props) => {
  const intl = useIntl()
  const honorificPrefixOther = intl.formatMessage({id: 'forms.honorific_prefix.other'});
  const honorificPrefixMister = intl.formatMessage({id: 'forms.honorific_prefix.mister'});
  const honorificPrefixMiss = intl.formatMessage({id: 'forms.honorific_prefix.miss'});
  return (
      <Fragment>
        <label className={styles['label-custom']}>
          {props.label}
          {props.required ? (
              <span className={styles.required}> *</span>
          ) : (
              <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
          )}
          <div style={{ float: 'right' }}>
            <span className={styles.error}>{props.touched && props.error}</span>
          </div>
        </label>
        <div className={classNames(styles['input-group'], props.shadow ? styles.drop_shadow : null)}>
          {props.readOnly === props.name ?
              <div>
                <input
                    type="radio"
                    name={props.name}
                    value={honorificPrefixMister}
                    id="gender-male"
                    checked={props.value === honorificPrefixMister}
                />
                <label
                    htmlFor="gender-male"
                    style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                >
                  {honorificPrefixMister}
                </label>
                <input
                    type="radio"
                    name={props.name}
                    value={honorificPrefixMiss}
                    id="gender-female"
                    checked={props.value === honorificPrefixMiss}
                />
                <label
                    htmlFor="gender-female"
                    style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                >
                  {honorificPrefixMiss}
                </label>
                {props.type === 'honorific_prefix_other' ?
                    <Fragment>
                      <input
                          type="radio"
                          name={props.name}
                          value={honorificPrefixOther}
                          id="gender-other"
                          checked={props.value === honorificPrefixOther}
                      />
                      <label
                          htmlFor="gender-other"
                          style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                      >
                        {honorificPrefixOther}
                      </label>
                    </Fragment> : null
                }
              </div> :

              <div>
                <input
                    type="radio"
                    name={props.name}
                    value={honorificPrefixMister}
                    id="gender-male"
                    onChange={props.onChange}
                    checked={props.value === honorificPrefixMister}
                />
                <label
                    htmlFor="gender-male"
                    style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                >
                  {honorificPrefixMister}
                </label>
                <input
                    type="radio"
                    name={props.name}
                    value={honorificPrefixMiss}
                    id="gender-female"
                    onChange={props.onChange}
                    checked={props.value === honorificPrefixMiss}
                />
                <label
                    htmlFor="gender-female"
                    style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                >
                  {honorificPrefixMiss}
                </label>
                {props.type === 'honorific_prefix_other' ?
                    <Fragment>
                      <input
                          type="radio"
                          name={props.name}
                          value={honorificPrefixOther}
                          id="gender-other"
                          onChange={props.onChange}
                          checked={props.value === honorificPrefixOther}
                      />
                      <label
                          htmlFor="gender-other"
                          style={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
                      >
                        {honorificPrefixOther}
                      </label>
                    </Fragment> : null
                }
              </div>
          }

        </div>
      </Fragment>
  )
}

const Date = (props) => {
  const [field, meta] = useField(props)
  return (
    <div className={styles['col-third']}>
      <div className={classNames(styles['input-group'], styles['input-group-icon'])}>
        <input
          className={props.shadow ? styles.drop_shadow : null}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type="text"
          placeholder={props.placeholder}
          value={field.value}
          readOnly={props.readOnly}
          style={meta.touched && meta.error ? { borderColor: 'var(--error-color)' } : null}
          onClick={() => props.activeInputName(props.name)}
        />
        <div className={styles['input-icon']}>
          <i className={`fas fa-calendar-alt`}></i>
        </div>
      </div>
      <div className={styles.error} style={{ marginTop: '-10px' }}>
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </div>
  )
}

const Birthday = (props) => {
  const [field, meta] = useField(props)
  return (
    <Fragment>
      <div>
        <label className={styles['label-custom']}>
          {props.label}
          {props.required ? (
            <span className={styles.required}> *</span>
          ) : (
            <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
          )}
        </label>
        <Fragment>
          <Field {...props} name={`${'bday_' + field.name}.day`} as={Date} required={true} placeholder={"DD"} onClick={() => props.activeInputName(field.name)} />
          <Field {...props} name={`${'bday_' + field.name}.month`} as={Date} required={true} placeholder={"MM"} onClick={() => props.activeInputName(field.name)} />
          <Field {...props} name={`${'bday_' + field.name}.year`} as={Date} required={true} placeholder={"YYYY"} onClick={() => props.activeInputName(field.name)} />
        </Fragment>
      </div>
    </Fragment>
  )
}

const TermsAndConditions = (props) => {
  return (
    <Fragment>
      <div className={styles['row-forms']}>
        <label className={styles['label-margin']}>
          {props.label} <span style={{ color: 'var(--required-color)' }}>*</span>
        </label>
        <div className={styles['input-group']}>
          {props.touched && props.error ? (
            <div className={styles['floating-checkbox-label']}></div>
          ) : null}
          <input
            type="checkbox"
            name={props.name}
            onChange={props.onChange}
            id="terms"
            checked={props.value}
          />
          {
            props.touchscreen_mode ?
              <label className={styles['terms-text']} htmlFor="terms">
                  <FormattedMessage id={"dataforms.policy"} />
              </label> :
              <label className={styles['terms-text']} htmlFor="terms" dangerouslySetInnerHTML={props.policy_text}></label>
          }
        </div>
        <p className={styles.error} style={{fontSize: '1.1em'}}>{props.touched && props.error}</p>
      </div>
    </Fragment>
  )
}

const MobilePhoneField = (props) => {
  return (
    <Fragment>
      <label className={styles['label-custom']}>
        {props.label}
        {props.required ? (
          <span className={styles.required}> *</span>
        ) : (
          <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
        )}
        <div style={{ float: 'right' }}>
          <span className={styles.error}>{props.touched && props.error}</span>
        </div>
      </label>
      <div
        className={classNames(
          styles['input-group'],
          styles['input-group-icon'],
          props.shadow ? styles.drop_shadow : null
        )}
      >
        <PhoneInput
          country={props.default_phone_country_code}
          name={props.name}
          onlyCountries={JSON.parse(props.possible_phone_country_codes)}
          onChange={props.onChange}
          onBlur={props.onBlur}
          inputStyle={props.touched && props.error ? { borderColor: 'var(--error-color)' } : null}
          onClick={() => props.activeInputName(props.name)}

        />
        <div className={styles['input-icon']}>
        </div>
      </div>
    </Fragment>
  )
}

const CustomerCardNumber = (props) => {
  const [field, meta] = useField(props)
  return (
    <Fragment>
      <div>
        <label className={styles['label-custom']}>
          {props.label}
          {props.required ? (
            <span className={styles.required}> *</span>
          ) : (
            <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
          )}
        </label>
        <div style={{display: 'flex'}}>
          <Field {...props} name={`${'customer_card_number_' + field.name}.bg`} as={Date} required={true}
                 placeholder={"12345"}
                 onClick={() => props.activeInputName(field.name)}/>
          <span style={{display: 'flex', fontSize: '3.8rem', marginRight: '10px'}}>//</span>
          <Field {...props} name={`${'customer_card_number_' + field.name}.bg_number`} as={Date} required={true}
                 placeholder={"1234567"}
                 onClick={() => props.activeInputName(field.name)}/>
        </div>
      </div>
    </Fragment>
  )
}

  export const CustomField = (props) => {
    const [field, touched, error] = useField(props)
    switch (props.type) {
      case 'text':
        return <TextField {...props} {...field} {...touched} {...error} icon={'fa-align-left'} readOnly={props.readOnly ? props.name : false} />
      case 'number':
        return <NumberField {...props} {...field} {...touched} {...error} icon={'fa-sort-numeric-up'} readOnly={props.readOnly ? props.name : false} />
      case 'text_area':
        return <TextArea {...props} {...field} {...touched} {...error} icon={'fa-align-left'} readOnly={props.readOnly ? props.name : false} />
      case 'options':
        return <RadioGroup {...props} {...field} {...touched} {...error} icon={'fa-mouse-pointer'} readOnly={props.readOnly ? props.name : false} />
    case 'check_box':
      return <CheckBox {...props} {...field} {...touched} {...error} readOnly={props.readOnly ? props.name : false} />
    case 'name':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-user'} readOnly={props.readOnly ? props.name : false} />
    case 'given_name':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-user'} readOnly={props.readOnly ? props.name : false} />
    case 'family_name':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-user'} readOnly={props.readOnly ? props.name : false} />
    case 'email':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-envelope'} readOnly={props.readOnly ? props.name : false} />
    case 'email_confirm':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-envelope'} confirm_text={<FormattedMessage id={'forms.email_confirm'}/>} readOnly={props.readOnly ? props.name : false}/>
    case 'tel':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-phone'} readOnly={props.readOnly ? props.name : false} />
    case 'bday':
      return <Birthday {...props} {...field} {...touched} {...error} icon={'fa-calendar-alt'} readOnly={props.readOnly ? props.name : false} />
    case 'street_address':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-address-card'} readOnly={props.readOnly ? props.name : false} />
    case 'postal_code':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-address-card'} readOnly={props.readOnly ? props.name : false} />
    case 'city':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-address-card'} readOnly={props.readOnly ? props.name : false} />
    case 'country_name':
      return <TextField {...props} {...field} {...touched} {...error} icon={'fa-globe'} readOnly={props.readOnly ? props.name : false} />
    case 'honorific_prefix':
      return <GenderPicker {...props} {...field} {...touched} {...error} readOnly={props.readOnly ? props.name : false} />
    case 'honorific_prefix_other':
      return <GenderPicker {...props} {...field} {...touched} {...error} readOnly={props.readOnly ? props.name : false} />
    case 'TermsAndConditions':
      return <TermsAndConditions {...props} {...field} {...touched} {...error} />
    case 'MobilePhoneNumber':
      return <MobilePhoneField {...props} {...field} {...touched} {...error} icon={'fa-phone'} readOnly={props.readOnly ? props.name : false} />
    case 'customer_card_number':
      return <CustomerCardNumber {...props} {...field} {...touched} {...error} icon={'fa-globe'} readOnly={props.readOnly ? props.name : false} />
    default:
      return null
  }
}
