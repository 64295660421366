import React, { useState, useEffect, useCallback } from "react";
import ReactFlagsSelect from "react-flags-select";

function getPossibleLocales(locales) {
    const countryCodes = []
    const localeToCountryCode = {
        "de": "DE",
        "en": "GB",
        "sv": "SE",
        "no": "NO",
        "da": "DK",
        "nl": "NL",
        "fr": "FR",
        "lb-LU": "LU",
        "el": "GR",
        "uk": "UA",
        "ru": "RU",
        "ar": "SA",
        "tr": "TR",
        "fa": "AF"
    }
    locales.forEach((locale) => {
        const countryCode = localeToCountryCode[locale]
        if (countryCode) {
            countryCodes.push(countryCode)
        }
    })
    return countryCodes
}

function getCountryLabels(countryCodes) {
    const countryCodeToLabel = {
        "DE": "Deutsch",
        "GB": "English",
        "SE": "Svenska",
        "NO": "Norsk",
        "DK": "Dansk",
        "NL": "Nederlands",
        "FR": "Français",
        "LU": "Letzeburgesch",
        "GR": "Ελληνικά",
        "UA": "Українська",
        "RU": "Русский",
        "SA": "العربية",
        "TR": "Türkçe",
        "AF": "فارسی"
    }
    const countryLabels = {}
    countryCodes.forEach((code) => {
        const label = countryCodeToLabel[code] || code
        countryLabels[code] = label
    })
    return countryLabels
}
const LanguageSelector = (props) => {
    const [selected, setSelected] = useState(null);

    let possible_locales = JSON.parse(props.possible_locales)
    let selected_language = null
    const countryCodes = getPossibleLocales(possible_locales)
    const countryLabels = getCountryLabels(countryCodes)
    switch (props.language) {
        case "de":
            selected_language = "DE"
            break;
        case "en":
            selected_language = "GB"
            break;
        case "sv":
            selected_language = "SE"
            break;
        case "no":
            selected_language = "NO"
            break;
        case "da":
            selected_language = "DK"
            break;
        case "fr":
            selected_language = "FR"
            break;
        case "nl":
            selected_language = "NL"
            break;
        case "lb-LU":
            selected_language = "LU"
            break;
        case "el":
            selected_language = "GR"
            break;
        case "uk":
            selected_language = "UA"
            break;
        case "ru":
            selected_language = "RU"
            break;
        case "ar":
            selected_language = "SA"
            break;
        case "tr":
            selected_language = "TR"
            break;
        case "fa":
            selected_language = "AF"
            break;
    }

    useEffect(() => {
        // change param in url when selected a language
        if (selected) {
            const url = new URL(window.location);
            switch (selected) {
                case "GB":
                    url.searchParams.set("lang", "en");
                    break;
                case "DK":
                    url.searchParams.set("lang", "da");
                    break;
                case "SE":
                    url.searchParams.set("lang", "sv");
                    break;
                case "NO":
                    url.searchParams.set("lang", "no");
                    break;
                case "FR":
                    url.searchParams.set("lang", "fr");
                    break;
                case "NL":
                    url.searchParams.set("lang", "nl");
                    break;
                case "LU":
                    url.searchParams.set("lang", "lb-LU");
                    break;
                case "GR":
                    url.searchParams.set("lang", "el");
                    break;
                case "UA":
                    url.searchParams.set("lang", "uk");
                    break;
                case "TR":
                    url.searchParams.set("lang", "tr");
                    break;
                case "AF":
                    url.searchParams.set("lang", "fa");
                    break;
                case "SA":
                    url.searchParams.set("lang", "ar");
                    break;
                case "RU":
                    url.searchParams.set("lang", "ru");
                    break;
                default:
                    url.searchParams.set("lang", selected.toLowerCase());
                    break;
            }
            window.location.replace(url.href);
        }
    }, [selected]);

    return (
        <ReactFlagsSelect
            alignOptionsToRight
            countries={countryCodes}
            placeholder="Deutsch"
            showSelectedLabel={true}
            selected={selected_language}
            onSelect={(code) => setSelected(code)}
            customLabels={countryLabels}
            optionsSize={props.textSize}
            height={"10px"}
        />
    )
}

export default LanguageSelector;

// customLabels={{ US: "EN-US", GB: "EN-GB", FR: "FR", DE: "DE", IT: "IT" }}
