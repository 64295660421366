import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import NavigationTop from "../navigation/NavigationTop";
import LanguageSelector from "../../helpers/LanguageSelector";

const style = {
  backgroundColor: 'white',
  padding: '10px',
  borderRadius: '3px'
};

const mobileStyle = {
    backgroundColor: 'white',
    padding: '6px',
    borderRadius: '3px'
}

const isMobileDevice = window.innerWidth <= 768;

class Header extends Component {
  render() {
    const { logo_align, logo, logo_height, logo_frame, show_header, custom_identifier, show_locale_selector } = this.props.site_info;
    const query = new URLSearchParams(window.location.search);
    const touchscreen_mode = query.get('touchscreen_mode') === 'true' ? true : false;
    const embedded_mode = query.get('embedded_mode') === 'true' ? true : false;

      return (
        <div className="container animated fadeIn zindexbooster_language_selector" style={{
            padding: show_header ? "10px 0 0 15px" : "30px 10px 0 15px"
           }}>
            <div className="row" style={touchscreen_mode ? { position: 'relative' } : {}}>
                {!show_header || custom_identifier === null ?
                    <div
                        className={
                            logo_align === 'right'
                                ? 'col-6 pull-right'
                                : logo_align === 'left'
                                    ? 'col-6 pull-left'
                                    : 'center-align'
                        }
                        style={{
                            ...(isMobileDevice
                                    ? (logo_frame
                                            ? logo_align === 'center' ? {
                                                ...mobileStyle,
                                                margin: '0 130px 0 130px',
                                            } : {
                                                ...mobileStyle,
                                                marginRight: logo_align === 'right' ? show_header && custom_identifier === null ? '15px' : '5px' : '0',
                                                marginLeft: logo_align === 'left' ? '2px' : '0',
                                            }
                                            : logo_align === 'center' ? {

                                            } : {
                                                marginRight: logo_align === 'right' ? '5px' : '0',
                                                marginLeft: logo_align === 'left' ? show_header && custom_identifier === null ? '-5px' : '3px' : '0',
                                            }
                                    )
                                    : (logo_frame
                                            ? logo_align === 'center' ? {
                                                ...style,
                                                width: `${logo_height < 100 ? '30%': logo_height < 150 ? '50%' : '80%'}`,
                                                margin: '0 auto',
                                            } : {
                                                ...style,
                                                marginRight: logo_align === 'right' ? show_header && custom_identifier === null ? '15px' : '5px' :  logo_align === 'center' && show_header && custom_identifier === null ? '15px' : '0',
                                            }
                                            : {
                                                margin: logo_align === 'center' ? '0 auto' : '0',
                                                marginRight: logo_align === 'right' ? '5px' : '0',
                                                marginLeft: logo_align === 'left' ? show_header && custom_identifier === null ? '-8px' : 0 : 0,
                                            }
                                    )
                            )
                        }}
                    >
                        <div style={{
                            paddingTop: embedded_mode === true ? '50px' : '10px',
                            marginBottom: `${isMobileDevice ? '15px' : '0'}`,
                            marginRight: `${show_header && custom_identifier === null && logo_align === 'right' ? '10px' : '0'}`,
                            marginLeft: `${show_header && custom_identifier === null && logo_align === 'left' ? '10px' : '0'}`
                        }}>
                          <img
                            className="header-logo no_tab img-fluid"
                            style={{
                              height: `${logo_height}px`,
                              width: `${logo_align === 'center' ? 'auto' : isMobileDevice ? '100%' : 'auto'}`,
                              margin: `${logo_align === 'center' ? '0 auto' : '0'}`,
                              maxHeight: `${logo_height}px`,
                              visibility: `${logo ? 'visible' : 'hidden'}`
                            }}
                            src={logo}
                            alt="logo"
                          />
                        </div>
                    </div> :
                    isMobileDevice ?
                        <div
                            className={
                                logo_align === 'right'
                                    ? 'col-6 pull-right'
                                    : logo_align === 'left'
                                        ? 'col-6 pull-left'
                                        : 'center-align'
                            }
                            style={logo_frame ? style : null}
                        >
                            <div style={{
                                paddingTop: embedded_mode === true ? '50px' : '10px',
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                marginLeft: "0px",
                                marginRight: `${logo_align === 'right' ? '15px' : '0'}`,
                            }}>
                              <img
                                className="header-logo no_tab img-fluid"
                                style={{
                                  height: `${logo_height}px`,
                                  width: '100%',
                                  maxWidth: '150px',
                                  maxHeight: `${logo_height}px`,
                                  visibility: `${logo ? 'visible' : 'hidden'}`
                                }}
                                src={logo}
                                alt="logo"
                              />
                            </div>
                        </div> : null
                }
                {show_locale_selector ? (
                    show_header ?
                        custom_identifier !== null ?
                            <div className={
                                logo_align === 'right'
                                    ? 'pull-left'
                                    : logo_align === 'left'
                                        ? 'pull-right'
                                        : 'pull-right'
                            }
                                 style={{ paddingTop: embedded_mode === true ? '50px' : isMobileDevice ? logo_frame ? '30px' : '15px' : '0', paddingRight: "16px"}}
                            >
                                <LanguageSelector
                                    possible_locales={this.props.possible_locales}
                                    language={this.props.language}
                                    textSize={isMobileDevice ? 13 : 14}
                                />
                            </div> : null
                        : embedded_mode ? null : touchscreen_mode ? <NavigationTop locales={this.props.locales} possible_locales={this.props.possible_locales} language={this.props.language} logo_align={logo_align} logo_height={this.props.logo_height}/> :  <div style={{ height: "${logo_height}", paddingTop: isMobileDevice ? logo_frame ? logo_align === 'center' ? '20px' : '40px' : '10px' : logo_frame ? logo_align === 'center' ? `${logo_height - 93}px` : `${logo_height - 13}px` :  logo_align === 'center' ? `${logo_height - 93}px` : `${logo_height - 33}px`}}><NavigationTop locales={this.props.locales} possible_locales={this.props.possible_locales} language={this.props.language} logo_align={logo_align} logo_height={this.props.logo_height}/></div>
                ) : null}

            </div>
        </div>


    )
        ;
  }
}

function mapStateToProps(state) {
    return {
        site_info: state.site_info,
        days: state.days,
        selected_service: state.user_selected_service,
        selected_day: state.user_selected_day,
        selected_subtask: state.user_selected_subtask,
        persons: state.user_count_persons
    };
}

export default injectIntl(connect(mapStateToProps)(Header));
