import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Image, Navbar, Nav, NavItem } from 'react-bootstrap';
import { injectIntl } from "react-intl";

class NavigationBottom extends Component {
  render() {
    const {intl} = this.props
    return (
      <Fragment>
        {
          (this.props.site_info.custom_identifier === 6 || this.props.site_info.custom_identifier === 7) ?
            <div style={{height: '200px'}} id="bottom_bar">
              <Navbar
                fixedBottom
                fluid
                className="footer__adac">
                <div className="container">
                  <div className="bottom__logo-adac">
                    <Image src="/images/adac.svg" className="logo-bottom-adac"/>
                  </div>
                  <div className="bottom__right-adac">
                    <div className="bottom__right-pos-adac">
                      <div className="bottom__right-line-adac">
                        <a href={this.props.site_info.custom_identifier === 6 ? "https://www.adac.de/der-adac/regionalclubs/wuerttemberg/infopflicht/" : "https://www.adac-niedersachsen-sachsen-anhalt.de/datenschutz.html"} target="_blank" rel='noopener noreferrer' style={{color: 'white', fontFamily: 'MiloADAC-Bold'}}>Datenschutz</a><div className=" bottom__links-data-adac">∣</div>
                      </div>
                      <div className="bottom__right-line-adac">
                        <a href={this.props.site_info.custom_identifier === 6 ? "https://www.adac.de/der-adac/regionalclubs/wuerttemberg/impressum/" : "https://www.adac-niedersachsen-sachsen-anhalt.de/impressum.html"} target="_blank" rel='noopener noreferrer' style={{color: 'white', fontFamily: 'MiloADAC-Bold'}}>Impressum</a><div className=" bottom__links-data-adac">∣</div>
                      </div>
                      <div className="bottom__right-line-adac">
                        <a href={this.props.site_info.custom_identifier === 6 ? "https://www.adac.de/kontakt-zum-adac/" : "https://www.adac-niedersachsen-sachsen-anhalt.de/kontakt.html"} target="_blank" rel='noopener noreferrer' style={{color: 'white', fontFamily: 'MiloADAC-Bold'}}>Kontakt</a>
                      </div>
                    </div>
                    <div className="pos-right-adac">
                      <div className="bottom-small-text-adac" style={{color: 'white', marginTop: '3px', fontFamily: 'MiloADAC-Bold'}}>{this.props.site_info.custom_identifier === 6 ? '© 2022 ADAC Württemberg e.V. - Alle Rechte vorbehalten.' : '© 2022 ADAC Niedersachsen / Sachsen-Anhalt e.V. - All rights reserved.'}</div>
                    </div>
                  </div>
                </div>
              </Navbar>
            </div> :
            this.props.site_info.custom_identifier === 8 ?
              <div style={{height: '200px'}} id="bottom_bar">
                <Navbar
                  fixedBottom
                  fluid
                  className="footer-labors">
                  <div className="container">
                    <div className="bottom-labors">
                      <div className="bottom__right-line-labors">
                        <a href="https://www.labors.at/datenschutz-agbs/" target="_blank"
                           rel="noopener noreferrer"
                           className="bottom__right-line-labors"
                           aria-label="Datenschutz &amp; AGBs">Datenschutz &amp; AGBs</a>
                      </div>
                      <div className="bottom__right-line-labors">
                        <a href="https://www.labors.at/impressum/" target="_blank" aria-label="Impressum"
                           rel="noopener noreferrer"
                           className="bottom__right-line-labors">Impressum</a>
                      </div>
                    </div>
                  </div>
                </Navbar>
              </div>
              :
              <div style={{paddingTop: '50px'}} id="navigation_bottom">
                <Navbar
                  fixedBottom
                  fluid
                  style={{backgroundColor: 'white', height: '50px', listStyle: 'none'}}>
                  {!this.props.site_info.hide_cleverq_logo ?
                    <Fragment>
                      <NavItem
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#">
                        <img
                          className="pull-left"
                          src="/images/cleverq-headerlogo-210x66.png"
                  alt="CleverQ Logo"
                  style={{height: '28px', marginTop: '10px', marginLeft: '0px'}}
                />
              </NavItem>
              <Navbar.Text style={{marginTop: '13px'}}>
                    <span className='web_appointment_footer_mobile'>
                      {intl.formatMessage({id: "navigation.cleverq"})}
                    </span>
              </Navbar.Text>
            </Fragment> : null
          }
          <Nav pullRight>
            <NavItem
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.legal_notice}>
              {intl.formatMessage({id: "navigation.legal_notice"})}
            </NavItem>
            <NavItem>|</NavItem>
            <NavItem
              target="_blank"
              rel="noopener noreferrer"
              href={
                this.props.policy
                  ? this.props.policy
                  : 'https://www.cleverq.de/datenschutzerklaerung/'
              }>
              {intl.formatMessage({id: "navigation.policy"})}
            </NavItem>
            {this.props.terms_of_use ?
              <Fragment>
                <NavItem>|</NavItem>
                <NavItem
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.terms_of_use}
                >
                  {intl.formatMessage({id: "navigation.terms_of_use"})}
                </NavItem>
              </Fragment>
                  : null}
                  {this.props.site_info.show_app_links ? (
                    <Fragment>
                      <NavItem
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/de/app/cleverq-3/id6474341785">
                        <img
                          src="/images/cleverq-appstore.png"
                          alt="AppleStore Store"
                          style={{
                            width: '100px',
                            marginRight: '-20px',
                            marginTop: '-5px'
                          }}
                        />
                      </NavItem>
                      <NavItem
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=de.com.bic.cleverq3&pcampaignid=web_share">
                        <img
                          src="/images/cleverq-fuer-android.png"
                          alt="Android Store"
                          style={{
                            width: '100px',
                            marginTop: '-5px'
                          }}
                        />
                      </NavItem>
                    </Fragment>
                  ) : null}
                </Nav>
              </Navbar>
            </div>
        }
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_language: state.user_selected_language
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    null
  )(NavigationBottom)
);
